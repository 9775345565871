import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`CPU usage of ECDN server.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> CPUUsage <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># CPU usage of kernel.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">system</GQLName>: [<GQLLink href="/ecdn-api-objects/PercentagePoint" mdxType="GQLLink">PercentagePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># CPU usage of user-space processes.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Nullable in case of errors (coming from an external service).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">user</GQLName>: [<GQLLink href="/ecdn-api-objects/PercentagePoint" mdxType="GQLLink">PercentagePoint</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`system: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/PercentagePoint"
      }}>{`PercentagePoint`}</a>{`!]`}</h3>
    <p>{`CPU usage of kernel.
Nullable in case of errors (coming from an external service).`}</p>
    <h3>{`user: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/PercentagePoint"
      }}>{`PercentagePoint`}</a>{`!]`}</h3>
    <p>{`CPU usage of user-space processes.
Nullable in case of errors (coming from an external service).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      